import logo from './logo.png';
import loading from './loading.gif';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logox" alt="logo" />
        <img src={loading} className="App-loading" alt="loading" />
        <p>
          bTaskee
        </p>
      </header>
    </div>
  );
}

export default App;
